import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	TextInput,
	Modal,
} from "react-native";
import OpenCoverPage from "./OpenCoverPage";
import logo_tetrice from "../../../assets/images/logo_tetrice.png";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
// import OpenPresentationData from "./OpenPresentationData";

import starFill from "./starFill.png";
import star from "./star.png";
import AutoHeightImage from "../../AutoHeightImageComp";
import CostEstimate from "./CostEstimate";
import SendingFeedback from "./SendingFeedback";
import FeedBackSent from "./FeedBackSent";
import DisclaimerPage from "./DisclaimerPage";
import LazyPresentation from "./OpenPresentationLaz";
import AuthAnimation from "./AuthAnimation";
import { api_validateShareToken } from "../../Api";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class OpenPresentation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			presentation_id: this.props.presentation_id,
			presentationData: "",
			presentationPages: [],
			presentationPageData: "",
			lockContent: true,

			sharedList: [],

			presentationRows: [],

			feedback: "",
			starRating: 0,

			sendingFeedback: false,
			feedBackSent: false,
			showAuthModal: true,
			authError: null,
			validatingToken: true,
		};
	}

	async componentDidMount() {
		await window.addEventListener("resize", this.resize.bind(this));
		await this.resize();

		// Validate token first
		await this.validateToken();
	}

	validateToken = async () => {
		let initialToken = this.props.token;

		if (!initialToken) {
			this.setState({
				validatingToken: false,
				authError: "No token provided",
			});
			return;
		}

		try {
			const url = api_validateShareToken;

			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					shareToken: initialToken,
				}),
			});

			if (!response.ok) {
				throw new Error("Invalid token");
			}

			const data = await response.json();
			const accessToken = data.accessToken;

			// Set validatingToken to false but keep modal visible
			this.setState({
				validatingToken: false,
			});

			// Wait for animation to complete
			await new Promise((resolve) => setTimeout(resolve, 2000));

			// Hide modal and proceed with data fetch
			this.setState({
				showAuthModal: false,
			});

			await this.fetchPresentationData(
				accessToken,
				this.props.presentation_id,
			);

			this.setState({
				presentationData: this.props.presentationData,
				presentationRows:
					typeof this.props.presentationData?.presentationRows !==
					"undefined"
						? this.props.presentationData?.presentationRows
						: [],
			});

			setTimeout(() => {
				this.setState({
					loading: false,
				});
			}, 2000);
		} catch (error) {
			this.setState({
				validatingToken: false,
				// authError: error.message,
			});
		}
	};

	fetchPresentationData = async (token, presentation_id) => {
		this.setState({
			loading: true,
			presentationData: "",
			presentationPages: [],
			presentationPageData: "",
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/updateRead-ba76-473e-89e6-2a7195aab6ec";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				presentation_id: presentation_id,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					// loading: false,
					presentationData: data,
					presentationRows:
						typeof data?.presentationRows !== "undefined"
							? data?.presentationRows
							: [],
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	fetchSharedList = async () => {
		this.setState({
			sharedList: [],
		});
		let obj = {
			presentation_id: this.state.presentation_id,
		};
		let url =
			"https://workflow.tetrice.co.za/webhook/bc943e69-cfd8-46fe-8f5d-1fba77801335";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(obj),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					sharedList: data,
				});
				this.fetchPresentationPages(data._id);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		if (this.state.showAuthModal) {
			return (
				<Modal animationType='fade' transparent={true} visible={true}>
					<View
						style={{
							flex: 1,
							// backgroundColor: "rgba(0,0,0,0.5)",
							backgroundColor: "#171A20",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								backgroundColor: "#171A20",
								padding: 20,
								borderRadius: 10,
								width: "90%",
								maxWidth: 400,
								alignItems: "center",
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: 150,
									height: 100,
									resizeMode: "contain",
									marginBottom: 20,
								}}
							/>

							<AuthAnimation
								isValidating={this.state.validatingToken}
								isAuthenticated={
									!this.state.validatingToken &&
									!this.state.authError
								}
							/>

							{this.state.validatingToken ? (
								<Text style={{ color: "#FFF", marginTop: 10 }}>
									Validating access...
								</Text>
							) : this.state.authError ? (
								<View>
									<Text
										style={{
											color: "#FF4444",
											marginTop: 10,
										}}>
										{this.state.authError}
									</Text>
									<TouchableOpacity
										style={{
											marginTop: 20,
											padding: 10,
											backgroundColor: "#0098FD",
											borderRadius: 5,
										}}
										onPress={() => {
											this.setState({
												validatingToken: true,
											});
											this.validateToken();
										}}>
										<Text style={{ color: "#FFF" }}>
											Retry
										</Text>
									</TouchableOpacity>
								</View>
							) : null}
						</View>
					</View>
				</Modal>
			);
		}

		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1100;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 14 : 18;
		let h3 = pp * 1.1618;
		let h2 = pp * 1.1618;
		let h1 = h2 * 1.1618;
		let h0 = h1 * 1.618;
		let maxFont = 40;

		let mw = 1000;

		let bg = "#171A20";

		if (this.state.sendingFeedback) {
			return <SendingFeedback />;
		} else if (this.state.feedBackSent) {
			return <FeedBackSent />;
		} else
			return (
				<View
					onLayout={() => {
						this.setState({
							// loading: false,
						});
					}}
					style={{
						width: "100%",
						height: this.state.screenHeight,
						// overflow: "hidden",
						backgroundColor: bg,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
						}}>
						<ScrollView
							ref={(ref) => {
								this.scrollRef = ref;
							}}
							overScrollMode='never'
							showsVerticalScrollIndicator={false}
							// pagingEnabled={true}
							// decelerationRate='fast'
							// scrollEventThrottle={160}
							// snapToInterval={this.state.screenHeight}
							contentContainerStyle={
								{
									// alignItems: "center",
								}
							}>
							<OpenCoverPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								mw={mw}
							/>
							<DisclaimerPage
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								mw={mw}
								fontSizes={{
									"--h1": `${h0}px`,
									"--h2": `${h1}px`,
									"--h4": `${h3}px`,
									"--pp": `${pp}px`,
								}}
							/>
							<View
								style={{
									marginHorizontal: "auto",
								}}>
								{this.state.presentationRows
									.sort((a, b) => {
										if (
											parseInt(a.order) <
											parseInt(b.order)
										)
											return -1;
										if (
											parseInt(a.order) >
											parseInt(b.order)
										)
											return 1;
										return 0;
									})
									.map((d, i) => (
										<View
											style={{
												borderBottomColor:
													APP_COLOURS.BG4,
												borderBottomWidth: 1,
												// maxWidth: 1400,
												// flex: 1,
												width: "100%",

												// alignItems: "center",
												justifyContent: "center",

												// minHeight:
												// 	window.innerHeight * 0.99,
											}}>
											{/* <View
												style={{
													position: "absolute",
													right: 10,
													top: 10,
												}}>
												<Text
													style={{
														fontWeight: "400",
														fontFamily:
															"Avenir Next",
														fontSize: 12,
														color: "#0098FD",
														marginBottom: 4,
														zIndex: 99,
														padding: 4,
														borderRadius: 20,
														backgroundColor: bg,
													}}>
													{parseInt(i) + 1}
												</Text>
											</View> */}
											<View
												style={{
													width: "100%",
													flex: 1,
													flexDirection: isSmallScreen
														? "column"
														: "row",
													// maxHeight: window.innerHeight,

													paddingBottom: 5,
													marginVertical: 10,

													// alignItems: "center",
													justifyContent: "center",
													paddingTop: "4%",
													// maxWidth: 1200,
												}}>
												{d.rowData.map((rd, rdIn) => (
													<View
														key={rd._id}
														onMouseEnter={() =>
															this.setState({
																hover: d.id,
															})
														}
														onMouseLeave={() =>
															this.setState({
																hover: "",
															})
														}
														style={{
															// flex: isSmallScreen
															// 	? "auto"
															// 	: 1,
															// flex: 1,
															padding: "4%",

															marginRight: 10,
															width: isSmallScreen
																? window.innerWidth
																: (window.innerWidth *
																		0.8) /
																  d.rowData
																		.length,
															maxWidth: 900,
														}}>
														{/* <Text
															style={{
																color: "#FFF",
																fontSize: 20,
															}}>
															{}
														</Text> */}
														<View
															style={{
																flexDirection:
																	"row",
																marginBottom: 5,
															}}>
															{rdIn === 0 ? (
																<Image
																	source={require("./tetrice.png")}
																	style={{
																		width:
																			pp *
																			(70 /
																				17),
																		height: pp,
																		resizeMode:
																			"contain",
																		position:
																			"absolute",
																		top: -pp,
																		left: 0,
																		// opacity: 0.8,
																	}}
																/>
															) : null}

															{/* <View
															style={{
																width: 200,
																// flex: 1,
																marginBottom: 20,
																marginRight:
																	"10%",
															}}>
															<img
																src={require("../../../assets/images/tetrice_logo.png")}
																alt='img'
																className='responsive'
															/>
														</View> */}
														</View>
														<LazyPresentation
															presentation_id={
																this.state
																	.presentation_id
															}
															pageData={rd}
															reloadData={(
																html,
															) => {}}
															lockContent={
																this.state
																	.lockContent !==
																rd._id
															}
															h0={h0}
															h1={h1}
															h2={h2}
															pp={pp}
															mw={mw}
															fontSizes={{
																"--h1": `${h0}px`,
																"--h2": `${h1}px`,
																"--h4": `${h3}px`,
																"--pp": `${pp}px`,
															}}
															index={rd._id}
														/>
														{/* <OpenPresentationData
															
														/> */}
													</View>
												))}
											</View>

											{/* <View
											style={{
												flex: 1,
												flexDirection: "row",
												// justifyContent: "space-between",
												flexWrap: "wrap",
												width: "100%",
												padding: "1%",
												justifyContent: "center",
											}}>
										
											<View
												style={{
													width: 200,
													// flex: 1,
													marginBottom: 20,
													marginRight: "10%",
												}}>
												<img
													src={require("../../../assets/images/services.png")}
													alt='img'
													className='responsive'
												/>
											</View>
											<View
												style={{
													width: 200,
													marginBottom: 20,
												}}>
												<img
													src={require("../../../assets/images/infod.png")}
													alt='img'
													className='responsive'
												/>
											</View>
										</View> */}
										</View>
									))}

								<View
									style={{
										height: 300,
									}}
								/>
							</View>

							{this.state.presentationData
								?.includeCostEstimate ? (
								<CostEstimate
									{...this.state.presentationData}
									title={this.state.presentationDatatitle}
									title2={this.state.presentationDatatitle2}
									end_date={
										this.state.presentationDataend_date
									}
									acceptProposal={(base64Signature) => {
										this.updateFeedback({
											presentation_id:
												this.props.presentation_id,

											feedback: "Signed & Accepted",
											accepted: true,
											base64Signature: base64Signature,
											starRating: this.state.starRating,
										});
									}}
									isSmallScreen={isSmallScreen}
								/>
							) : null}

							<View
								style={{
									flex: 1,
									flexDirection: "row",
									// justifyContent: "space-between",
									flexWrap: "wrap",
									width: "100%",
									padding: "1%",
									justifyContent: "center",
									marginTop: 50,
								}}>
								{/* <View
													style={{
														flex: 1,
														minWidth: 200,
													}}>
													<Image
														source={require("./tetrice.png")}
														style={{
															width: 100,
															height: 24,
															resizeMode:
																"contain",
															// position: "absolute",
															// top: 10,
															// left: 10,
														}}
													/>
												</View> */}
								<View
									style={{
										width: 200,
										// flex: 1,
										marginBottom: 20,
										marginRight: "10%",
									}}>
									<img
										src={require("../../../assets/images/services.png")}
										alt='img'
										className='responsive'
									/>
								</View>
								<View
									style={{
										width: 200,
										marginBottom: 20,
									}}>
									<img
										src={require("../../../assets/images/infod.png")}
										alt='img'
										className='responsive'
									/>
								</View>
							</View>

							<View
								style={{
									width: "100%",
									marginTop: 100,
									justifyContent: "center",
								}}>
								<View
									style={{
										// width: 250,
										width: "100%",
										flexDirection: "row",
										marginBottom: 20,
										justifyContent: "center",
									}}>
									{[1, 2, 3, 4, 5, 6, 7].map((priority) => (
										<TouchableOpacity
											key={priority}
											style={{
												marginRight: 5,
											}}
											onPress={async () => {
												this.setState({
													starRating: priority,
												});
											}}>
											{this.state.starRating >=
											priority ? (
												<AutoHeightImage
													width={35}
													style={{
														marginRight: 5,
													}}
													source={starFill}
												/>
											) : (
												<AutoHeightImage
													width={35}
													style={{
														marginRight: 5,
														opacity: 0.3,
													}}
													source={star}
												/>
											)}
										</TouchableOpacity>
									))}
								</View>

								<View
									style={{
										padding: 10,
										maxWidth: 500,
										width: "100%",
										marginHorizontal: "auto",
									}}>
									<Text
										style={{
											fontWeight: "400",
											fontFamily: "Avenir Next",
											fontSize: 12,
											color: "#0098FD",
											marginBottom: 4,
											position: "absolute",
											top: -4,

											zIndex: 99,
											padding: 4,
											left: 20,
											borderRadius: 20,
											backgroundColor: bg,
										}}>
										Feedback
									</Text>

									<TextInput
										ref={(ref) =>
											(this.feedbackinputRef = ref)
										}
										style={{
											fontSize: 16,
											color: "#FFF",
											fontWeight: "400",
											fontFamily: "Avenir Next",
											width: "100%",
											paddingLeft: 10,
											padding: 20,
											borderWidth: 1,
											borderColor: "#0098FD",
											borderRadius: 20,
											borderWidth: 1,
											minHeight: 30,
											backgroundColor: bg,
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										multiline
										numberOfLines={5}
										clearButtonMode='while-editing'
										// autoCompleteType="off"
										// value={feedback}
										placeholder={`Type your feedback`}
										onChangeText={(text) => {
											this.setState({
												feedback: text,
											});
										}}
									/>
									<TouchableOpacity
										style={{
											width: "100%",
											borderRadius: 10,
											justifyContent: "center",
											alignItems: "center",
											marginVertical: 25,
											flexDirection: "row",
											overflow: "hidden",
											marginTop: 30,
											opacity:
												this.state.feedback !== ""
													? 1
													: 0.2,
										}}
										onPress={() => {
											// setTimeout(() => {
											this.updateFeedback({
												presentation_id:
													this.props.presentation_id,

												feedback: this.state.feedback,
												starRating:
													this.state.starRating,
											});
											// }, 1500);
										}}>
										<Image
											source={
												require("./button.svg").default
											}
											style={{
												width: "101%",
												height: 45,
												resizeMode: "cover",
											}}
										/>

										<Text
											style={{
												fontSize: 16,
												// marginTop: 12,
												fontWeight: "600",
												color: "#FFF",
												position: "absolute",
											}}>
											Send
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</View>

					{this.state.loading ? (
						<Modal
							animationType='none'
							transparent={true}
							visible={this.state.loading}>
							<View
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.6)",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<TouchableOpacity
									activeOpacity={1}
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										backgroundColor: "rgba(0, 0, 0, 0.6)",
										zIndex: 10,
										position: "absolute",
										top: 0,
									}}
									onPress={() => {
										this.setState({
											accept: true,
											showSignaturePad: false,
										});
									}}
								/>
								<View
									style={{
										// width: window.innerWidth,
										height: window.innerHeight,
										backgroundColor: bg,
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										position: "absolute",
										top: 0,
										zIndex: 999,
										width: "100%",
									}}>
									<Image
										source={logo_tetrice}
										style={{
											width: 150,
											height: 100,
											resizeMode: "contain",
										}}
									/>
								</View>
							</View>
						</Modal>
					) : null}
				</View>
			);
	}

	updateFeedback = (obj) => {
		this.setState({
			sendingFeedback: true,
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/0e3474fd-75c3-4c62-870d-b34961372276";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${this.props.token}`,
			},
			body: JSON.stringify(obj),
		};
		fetch(url, requestOptions)
			.then((response) => {
				this.setState({
					sendingFeedback: false,
					feedBackSent: true,
				});
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	updateDocument = (obj) => {
		// let url =
		// 	"https://workflow.tetrice.co.za/webhook/fa683270-ffec-4542-beff-a2acafd63bfd";
		// const requestOptions = {
		// 	method: "PUT",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		authorization: `Bearer ${this.props.presentation_id}`,
		// 	},
		// 	body: JSON.stringify(obj),
		// };
		// fetch(url, requestOptions)
		// 	.then((response) => {
		// 		if (!response.ok) {
		// 			throw new Error("Failed to fetch data");
		// 		}
		// 		return response.json();
		// 	})
		// 	.then((data) => {
		// 		console.log(data);
		// 		this.setState({
		// 			presentationData: data,
		// 			presentationRows: data.presentationRows,
		// 			loading: false,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		this.setState({
		// 			error: error,
		// 			loading: false,
		// 		});
		// 	});
	};
}
